import { Answer as AnswerType } from "core/theme/default/components/Quiz";
export type UserStateType = {
	token: String | null;
	firstname?: String;
	lastname?: String;
	email?: String;
	addresses?: {
		street: String;
		city: String;
		postcode: String;
		country_code: String;
	}[];
	quiz?: {
		qtys?: AnswerType;
		price?: AnswerType;
		frequency?: AnswerType;
	};
};

const userDefaultState: UserStateType = {
	token: null,
};

const cartDefaultState = {
	id: "",
};

const defaultStates = {
	user: userDefaultState,
	cart: cartDefaultState,
};

export default defaultStates;
