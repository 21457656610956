import { ApolloClient, InMemoryCache, HttpLink, from } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { useSelector } from "react-redux";
import { RootState } from "core/state";

const errorLink = onError(({ graphQLErrors, networkError }) => {
	if (graphQLErrors) {
		graphQLErrors.forEach(({ message }) => {
			console.error(message);
		});
	}
	if (networkError) {
		console.error("Graphql Network Error");
	}
});

const link = from([
	errorLink,
	new HttpLink({
		// eslint-disable-next-line no-undef
		uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
		credentials: "include",
		useGETForQueries: true,
	}),
]);

const authLink = setContext((_, { headers }) => {
	// get the authentication token from local storage if it exists
	const token = useSelector((state: RootState) => state.user.token);
	// return the headers to the context so httpLink can read them
	return {
		headers: {
			...headers,
			authorization: token ? `Bearer ${token}` : "",
		},
	};
});

export const apolloClient = new ApolloClient({
	cache: new InMemoryCache(),
	link: authLink.concat(link),
});
