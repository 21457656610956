import { actionType } from "core/state";
import defaultStates from "core/state/bootstrap/defaultStates";
import _ from "lodash";

const setCartToken = (
	state: typeof defaultStates.cart,
	payload: { id: string }
): typeof defaultStates.cart => {
	if (!_.isEmpty(payload.id)) {
		state.id = payload.id;
	} else {
		console.error("Unable to set cart token");
	}

	return state;
};

const setCartData = (
	state: typeof defaultStates.cart,
	payload: {
		billing_address?: {
			street: String;
			city: String;
			postcode: String;
			country: {
				code: String;
				label: String;
			};
		};
		shipping_addresses?: [
			{
				street: String;
				city: String;
				postcode: String;
				country: {
					code: String;
					label: String;
				};
				available_shipping_methods: [
					{
						code: String;
						title: String;
					}
				];
			}
		];
		available_payment_methods: [
			{
				code: String;
			}
		];
	}
): typeof defaultStates.cart => {
	if (!_.isEmpty(payload)) {
		state = { ...state, ...payload };
	} else {
		console.error("Unable to set user token");
	}

	return state;
};

export const ACTIONS = [
	{ id: "SET_CART_TOKEN", action: setCartToken },
	{ id: "SET_CART_DATA", action: setCartData },
];

export const cartReducer = (state = defaultStates.cart, action: actionType) => {
	return (
		ACTIONS.find(({ id }) => {
			return id === action.type;
		})?.action(state, action.payload) || state
	);
};
