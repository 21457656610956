export default `mutation ($firstname: String, $lastname: String, $email: String, $password: String) {
  createCustomer(
    input: {
      firstname: $firstname
      lastname: $lastname
      email: $email
      password: $password
    }
  ) {
    customer {
      firstname
      lastname
      email
    }
  }
}`;
