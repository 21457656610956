import _ from "lodash";
import defaultStates from "core/state/bootstrap/defaultStates";
import { saveState } from "core/state/bootstrap/localStorage";

export function getFullStoreState(store: any = {}) {
	let storeState =
		typeof store?.getState === "function" ? store.getState() : {};
	return { ...defaultStates, ...storeState };
}

const getStateByKey = (store: any, key: any, projectStates: any) => {
	let thisState = {};
	if (!_.isEmpty(store[key])) {
		thisState = store[key];
	} else {
		thisState = projectStates[key]();
	}
	return thisState;
};

export const projectStates = {};

const generateAppPersistedState = (store: any, list = {}) => {
	let persistedList: any = {};
	Object.keys(list).map((key) => {
		// eslint-disable-next-line no-prototype-builtins
		if (list.hasOwnProperty(key)) {
			let data = getStateByKey(store, key, projectStates);
			persistedList[key] = data;
			return persistedList;
		}
		return false;
	});
	return persistedList;
};

const subscribeDataPersister = (store: any) => {
	// eslint-disable-next-line no-undef
	let throttle: number = parseInt(process.env?.REACT_APP_CACHE_TIME || "200");
	store.subscribe(
		_.throttle(() => {
			let storeState = store.getState();
			let allStatesData = getFullStoreState(store);

			saveState({
				...allStatesData,
				...generateAppPersistedState(storeState),
			});
		}, throttle)
	);
};

export default subscribeDataPersister;
