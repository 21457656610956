import { useDispatch, useSelector } from "react-redux";
import { RootState } from "core/state";
import axios from "axios";
import CUSTOMER_CART from "core/gql/queries/customerCart";
import MERGE_CART from "core/gql/mutations/mergeCart";

// import SET_SHIPPING_ADDRESS from "core/gql/mutations/setBillingAddressOnCart";
// import SET_BILLING_ADDRESS from "core/gql/mutations/setShippingAddressOnCart";
import SET_BOTH_ADDRESS from "core/gql/mutations/setBothAddressesOnCart";

import { useState } from "react";
const useCart = () => {
	const cart = useSelector((state: RootState) => state.cart);
	const user = useSelector((state: RootState) => state.user);
	const [lock] = useState(false);
	const dispatch = useDispatch();

	const getUserCartId = (token: String = user?.token || "") => {
		axios
			.post(
				// eslint-disable-next-line no-undef
				process.env?.REACT_APP_GRAPHQL_ENDPOINT || "",
				{
					query: CUSTOMER_CART,
				},
				{
					headers: {
						"Content-type": "application/json",
						Authorization: "Bearer " + token,
					},
				}
			)
			.then((res) => {
				const newCartId = res.data.data.customerCart.id || null;

				if (newCartId && newCartId !== cart.id) {
					mergeCarts(token.toString(), newCartId);
				}
				dispatch({
					type: "SET_CART_TOKEN",
					payload: { id: res.data.data.customerCart.id },
				});
			});
	};

	const mergeCarts = (
		userToken: String = user?.token || "",
		userCartId: string
	) => {
		axios
			.post(
				// eslint-disable-next-line no-undef
				process.env?.REACT_APP_GRAPHQL_ENDPOINT || "",
				{
					query: MERGE_CART,
					variables: { guestCart: cart.id, userCart: userCartId },
				},
				{
					headers: {
						"Content-type": "application/json",
						Authorization: "Bearer " + userToken,
					},
				}
			)
			.then(() => {
				// @TODO: Do something with the errors?
			});
	};

	const setAddressesOnCart = (addresses: {
		shipping: {
			city: string;
			country_code: string;
			postcode: string;
			street: Array<string>;
		};
		billing?: {
			city: string;
			country_code: string;
			postcode: string;
			street: Array<string>;
		};
	}) => {
		let shippingAddress = {
			firstname: user.firstname,
			lastname: user.lastname,
			telephone: "123456789",
			...addresses.shipping,
		};
		let billingAddress = {
			firstname: user.firstname,
			lastname: user.lastname,
			telephone: "123456789",
			...(addresses?.billing || addresses.shipping),
		};
		axios
			.post(
				// eslint-disable-next-line no-undef
				process.env?.REACT_APP_GRAPHQL_ENDPOINT || "",
				{
					query: SET_BOTH_ADDRESS,
					variables: {
						cartId: cart.id,
						shipping_address: shippingAddress,
						billing_address: billingAddress,
					},
				},
				{
					headers: {
						"Content-type": "application/json",
						Authorization: "Bearer " + user?.token,
					},
				}
			)
			.then((res) => {
				const newCartState = res.data.data.setBillingAddressOnCart.cart;
				dispatch({
					type: "SET_CART_DATA",
					payload: { ...newCartState },
				});
			});
	};

	return { getUserCartId, setAddressesOnCart, lock };
};

export default useCart;
