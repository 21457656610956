import { RootState } from "core/state";
// eslint-disable-next-line no-undef
export const loadState = (key = process.env.REACT_APP_CACHE_KEY) => {
	let myKey = key || "";

	if (myKey === "") {
		console.error("Cache key not defined, check .env");
		return undefined;
	}

	try {
		const serializedState = localStorage.getItem(myKey);
		if (serializedState === null) {
			return undefined;
		}
		return JSON.parse(serializedState);
	} catch (err) {
		console.error(err.message);
		return undefined;
	}
};

export const saveState = (
	state: RootState,
	// eslint-disable-next-line no-undef
	key = process.env.REACT_APP_CACHE_KEY
) => {
	try {
		const serializedState = JSON.stringify(state);
		if (typeof key === "string") {
			localStorage.setItem(key, serializedState);
		}
	} catch (err) {
		console.error(err.message);
		return undefined;
	}
};
