// Has to be a string since we're using Axios for Authentication instead of Apollo
export default `
	mutation (
		$cartId:String!
		$shipping_address: CartAddressInput!
		$billing_address: CartAddressInput!
	) {
		setShippingAddressesOnCart(
			input: {
				cart_id: $cartId,
				shipping_addresses: [{address:$shipping_address}]
			}
		) {
			cart {
				id
			}
		}	
		setBillingAddressOnCart(
			input: {
				cart_id: $cartId,
				billing_address: {
					address: $billing_address
				}
			}
		) {
			cart {
        billing_address {
          street
          city
          postcode
          country {
            code
            label
          }
        }
        shipping_addresses {
          street
          city
          postcode
          country {
            code
            label
          }
					available_shipping_methods{
						carrier_code
						carrier_title
						method_code
						method_title
					}
        }
				available_payment_methods {
					code
					title
				}
			}
		}
	}
`;
