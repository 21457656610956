// Has to be a string since we're using Axios for Authentication instead of Apollo
export default `
	query {
		customer {
			firstname
			lastname
			email
			addresses {
				street
				city
				postcode
				country_code
			}
		}
	}
`;
