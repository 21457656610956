import React,{ lazy, Suspense } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
const App = lazy(() => import("core"));
const Header = lazy(()=> import ("core/theme/default/layout/Header"));
const Footer = lazy(()=> import("core/theme/default/layout/Footer"));
const Register = lazy(()=> import("core/theme/default/pages/Customer/Register"));
const Landing = lazy(()=> import("core/theme/default/pages/Landing"));
const CustomerDashboard = lazy(()=> import("core/theme/default/pages/Customer/Dashboard"));
import useUser from "core/hooks/userHooks";
import axios from "axios";
import CREATE_EMPTY_CART from "core/gql/mutations/createEmptyCart";
import { useDispatch } from "react-redux";
import useApp from "core/hooks/appHooks";

const Router: React.FC<any> = () => {
	let { isLandingOnlyApp } = useApp();
	let { isLoggedIn } = useUser();
	let dispatch = useDispatch();
	// Bootstrapping
	React.useEffect(() => {
		// Generate a Cart ID
		if (!isLoggedIn && !isLandingOnlyApp) {
			axios
				.post(
					// eslint-disable-next-line no-undef
					process.env?.REACT_APP_GRAPHQL_ENDPOINT || "",
					{
						query: CREATE_EMPTY_CART,
					},
					{
						headers: { "Content-type": "application/json" },
					}
				)
				.then((res) => {
					// Implement state propagation
					const cartId = res.data.data.createEmptyCart;
					dispatch({
						type: "SET_CART_TOKEN",
						payload: { id: cartId },
					});
				});
		}

		// Experimental Feature
		if (isLoggedIn && window.location.pathname === "/") {
			window.location.pathname = "/account";
		}
	}, []);
	return (
		<BrowserRouter>
			<div className="main-container flex flex-col min-h-screen">
				{!isLandingOnlyApp && <Header />}
				<Switch>
					{!isLandingOnlyApp && (
						<>
							<Route path="/account">
								<CustomerDashboard />
							</Route>
							<Route path="/register">
								<Register />
							</Route>
							<Route path="/checkout">
								<Register />
							</Route>
						</>
					)}
					<Route path="/">
						{isLandingOnlyApp && <Suspense fallback={<p></p>}> <Landing /></Suspense>}
						{!isLandingOnlyApp && <App />}
					</Route>
				</Switch>
				{!isLandingOnlyApp && <Footer />}
			</div>
		</BrowserRouter>
	);
};

export default Router;
