import { createStore, combineReducers } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { userReducer as user } from "core/state/userState";
import { cartReducer as cart } from "core/state/cartState";
import { loadState } from "core/state/bootstrap/localStorage";
import subscribeDataPersister, {
	getFullStoreState,
} from "core/state/bootstrap/persistedState";

export type actionType = {
	type: string;
	payload: any;
};

// eslint-disable-next-line no-undef
let persistedState = loadState(process.env.REACT_APP_CACHE_KEY);
const fullStoreState = getFullStoreState();
const rootReducer = combineReducers({ user, cart });
const composeEnhancers = composeWithDevTools({});

// Caching and Persisted State
persistedState =
	persistedState === undefined || Object.keys(persistedState).length === 0
		? fullStoreState
		: persistedState;

let store = createStore(rootReducer, persistedState, composeEnhancers());
subscribeDataPersister(store);

export type RootState = ReturnType<typeof rootReducer>;
export default store;
