import { useDispatch, useSelector } from "react-redux";
import { RootState } from "core/state";
import axios from "axios";
import LOGIN from "core/gql/mutations/login";
import REGISTER from "core/gql/mutations/register";
import SUB_TO_NEWSLETTER from "core/gql/mutations/newsletterSubscribe";
import CUSTOMER from "core/gql/queries/customer";
import { useState } from "react";
import useCart from "./cartHooks";
import { UserStateType } from "core/state/bootstrap/defaultStates";
const useUser = () => {
	const user = useSelector((state: RootState) => state.user);
	const { getUserCartId } = useCart();
	const [lock, setLock] = useState(false);

	const isLoggedIn = user?.token;
	const dispatch = useDispatch();

	const login = (loginData: { email: string; password: string }) => {
		if (!loginData.email || !loginData.password) {
			return false;
		}
		try {
			let token = "";
			setLock(true);
			axios
				.post(
					// eslint-disable-next-line no-undef
					process.env?.REACT_APP_GRAPHQL_ENDPOINT || "",
					{
						query: LOGIN,
						variables: { ...loginData },
					},
					{
						headers: { "Content-type": "application/json" },
					}
				)
				.then((res) => {
					// Implement state propagation
					token = res.data.data.generateCustomerToken.token;
					dispatch({
						type: "SET_TOKEN",
						payload: {
							token: token,
						},
					});
					getUserData(token);
					getUserCartId(token);
					setLock(false);
				});
		} catch (e) {
			console.error(e);
		}
		return { success: true, error: null };
	};

	const getUserData = (token: string) => {
		axios
			.post(
				// eslint-disable-next-line no-undef
				process.env?.REACT_APP_GRAPHQL_ENDPOINT || "",
				{
					query: CUSTOMER,
				},
				{
					headers: {
						"Content-type": "application/json",
						Authorization: "Bearer " + token,
					},
				}
			)
			.then((res) => {
				dispatch({
					type: "SET_USER_DATA",
					payload: res.data.data.customer,
				});
			});
	};

	const setData = (payload: UserStateType) => {
		dispatch({
			type: "SET_USER_DATA",
			payload: payload,
		});
	};

	const logout = () => {
		return null;
	};

	const register = (
		userData: {
			firstname: string;
			lastname: string;
			email: string;
			password: string;
		},
		shouldLogin: boolean = false
	) => {
		if (
			!userData.firstname ||
			!userData.lastname ||
			!userData.email ||
			!userData.password
		) {
			console.error("Missing data for Registration\nData passed:", userData);
			return false;
		}
		new Promise((resolve, reject) => {
			try {
				axios
					.post(
						// eslint-disable-next-line no-undef
						process.env?.REACT_APP_GRAPHQL_ENDPOINT || "",
						{
							query: REGISTER,
							variables: { ...userData },
						},
						{
							headers: { "Content-type": "application/json" },
						}
					)
					.then((res) => {
						// Implement state propagation
						if (!res.data.errors) {
							resolve(true);
						} else {
							reject(res.data.errors[0].message);
						}
					});
			} catch (e) {
				console.error(e);
			}
		}).then(
			() => {
				if (shouldLogin) {
					login(userData);
				}
			},
			(message) => {
				return message;
			}
		);
	};

	const subscribeToNewsletter = async (email: string) => {
		return axios
			.post(
				// eslint-disable-next-line no-undef
				process.env?.REACT_APP_GRAPHQL_ENDPOINT || "",
				{
					query: SUB_TO_NEWSLETTER,
					variables: { email: email },
				},
				{
        // eslint-disable-next-line no-undef
        headers: { "Content-type": "application/json", "Store": process.env?.REACT_APP_STORE_CODE || "main_website_store_se"},
				}
			)
			.then((res) => {
				// Implement state propagation
				if (
					res.data.data?.subscribeEmailToNewsletter?.status === "SUBSCRIBED" ||
					res.data.errors?.[0].message.includes("already subscribed")
				) {
					return true;
				} else {
					return false;
				}
			});
	};

	return {
		isLoggedIn,
		login,
		logout,
		user,
		register,
		lock,
		setData,
		subscribeToNewsletter,
	};
};

export default useUser;
