import { actionType } from "core/state";
import defaultStates from "core/state/bootstrap/defaultStates";
import _ from "lodash";
import { Answer as AnswerType } from "core/theme/default/components/Quiz";

const setToken = (
	state: typeof defaultStates.user,
	payload: { token: string }
): typeof defaultStates.user => {
	if (!_.isEmpty(payload.token)) {
		state.token = payload.token;
	} else {
		console.error("Unable to set user token");
	}

	return state;
};

const setData = (
	state: typeof defaultStates.user,
	payload: {
		firstname?: string;
		lastname?: string;
		email?: string;
		addresses?: {
			street: string;
			city: string;
			postcode: string;
			country_code: string;
		}[];
		quiz?: {
			qtys?: AnswerType;
			price?: AnswerType;
			frequency?: AnswerType;
		};
	}
): typeof defaultStates.user => {
	if (!_.isEmpty(payload)) {
		state = { ...state, ...payload };
	} else {
		console.error("Unable to set user token");
	}

	return state;
};

export const USER_ACTIONS = [
	{ id: "SET_TOKEN", action: setToken },
	{ id: "SET_USER_DATA", action: setData },
];

export const userReducer = (state = defaultStates.user, action: actionType) => {
	return (
		USER_ACTIONS.find(({ id }) => {
			return id === action.type;
		})?.action(state, action.payload) || state
	);
	// switch (action.type) {
	// 	case USER_ACTIONS.SET_TOKEN.id:
	// 		return USER_ACTIONS.SET_TOKEN.action(state, action.payload);
	// 	case USER_ACTIONS.SET_DATA.id:
	// 		return USER_ACTIONS.SET_DATA.action(state, action.payload);
	// 	default:
	// 		return state;
	// }
};
